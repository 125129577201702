<template>
  <PhotosTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
  />
</template>

<script>
import PhotosTemplate from "@/components/templates/Photos/Photos";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    PhotosTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "Photos",
      },
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
</style>
